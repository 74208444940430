

/* CSS for Redux */
.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.alert-light {
  background: var(--light-color);
  color: #333;
}

.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.alert-success {
  background: var(--dark-success);
  color: #fff;
}

.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

/* CSS for Calendar */
.App {
  text-align: center;
}
.rbc-btn-group:last-child{
  display: block;
  white-space: initial;
}

h1, h2 {
  padding: 10px;
}

#calendarDiv {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 10px;
  height: 600px;
}

#eventButton {
  margin-bottom: 10px;
}

.rbc-event-content {
  white-space: normal !important;
}





/* Css for Calendar */
h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
}

p {
  line-height: 1.55;
  color: #333;
  font-size: 400;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
}

h1 {
  font-size: 62px;
}

h2 {
  font-size: 30px;
  margin-bottom: 65px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

/* ==================================================
  General
  ================================================== */
a {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

/* ==================================================
  Elements
  ================================================== */
.btn {
  background-color: #199EB8;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 800;
  color: #fff;
  padding: 15px 45px;
  border-radius: 50px;
}

.btn:hover {
  background-color: #E04F00;
  color: #fff;
}

.btn:focus {
  color: #fff;
}

.btn-ghost {
  border: 3px solid #fff;
  background-color: transparent;
}

.btn-ghost:hover {
  background-color: #fff;
  color: #199EB8;
}

section {
  padding: 85px 0;
}

.card {
  position: relative;
  padding: 0;
  border: 0;
  border-radius: 0;
  margin: 0;
  overflow: hidden;
}

a:hover {
  text-decoration: none !important;
}

.scrolltop {
  display: none;
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #199EB8;
  color: #fff;
  text-align: center;
  font-size: 24px;
}

.scrolltop:hover, .scrolltop:active, .scrolltop:focus {
  color: #fff !important;
  opacity: .75;
}

/* ==================================================
  Hero Styling
================================================== */
.hero {
  display: table;
  position: relative;
  background-size: cover;
  padding: 150px 0;
  color: #fff;
  width: 100%;
  height: 100vh;
  
}

.hero:after {
  content: '';
  z-index: 0;
  position: absolute;
  background-image: url("../images/Background.jpg");
  -webkit-background-size: cover;
  mix-blend-mode: darken;
-moz-background-size: cover;

-o-background-size: cover;

background-size: cover;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.hero .container {
  position: relative;
  z-index: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}
.horseLogo {
  height: 60px;

}
.hero-brand {
  margin-bottom: 75px;
  display: inline-block;
  
}

.hero-brand:hover {
  opacity: .75;
}

.tagline {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-size: 26px;
  margin: 45px 0 75px 0;
  color: #fff;
}
/* ==================================================
  Header Styling
================================================== */
#header {
  background: #199EB8;
  height: 70px;
}

#header #logo {
  margin: 14px 25px 0 0;
}

#header #logo h1 {
  font-size: 36px;
  margin: 0;
  padding: 2px 0;
  line-height: 1;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header #logo h1 a, #header #logo h1 a:hover {
  color: #fff;
}

#header #logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

@media (max-width: 768px) {
  #header {
    height: 50px;
  }
  #header #logo {
    margin: 10px 0 0 0;
  }
  #header #logo h1 {
    padding: 2px 0;
    font-size: 26px;
  }
  #header #logo img {
    max-height: 30px;
  }
}

#header .social-nav {
  margin-top: 15px;
}

#header .social-nav a {
  font-size: 24px;
  margin-left: 15px;
  color: #fff;
}

#header .social-nav a:hover {
  color: rgba(255, 255, 255, 0.75);
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Nav Menu Essentials */
.auth-nav-menu, .nav-menu, .nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.auth-nav-menu, .nav-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99;
}

.auth-nav-menu, .nav-menu li {
  position: relative;
  white-space: nowrap;
}

.auth-nav-menu, .nav-menu > li {
  float: left;
}

.auth-nav-menu, .nav-menu li:hover > ul,
.auth-nav-menu, .nav-menu li.sfHover > ul {
  display: block;
}

.auth-nav-menu, .nav-menu ul ul {
  top: 0;
  left: 100%;
}

.auth-nav-menu, .nav-menu ul li {
  min-width: 180px;
}

/* Nav Menu Arrows */
.sf-arrows .sf-with-ul {
  padding-right: 30px;
}

.sf-arrows .sf-with-ul:after {
  content: "\f107";
  position: absolute;
  right: 15px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

.sf-arrows ul .sf-with-ul:after {
  content: "\f105";
}

/* Nav Meu Container */
#nav-menu-container {
  margin: 0;
}

@media (max-width: 768px) {
  #nav-menu-container {
    display: none;
  }
}

/* Nav Meu Styling */
.auth-nav-menu, .nav-menu a {
  padding: 22px 15px 18px 15px;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 15px;
  outline: none;
}

.auth-nav-menu, .nav-menu a:hover, .nav-menu li:hover > a, .nav-menu .menu-active > a {
  color: rgba(255, 255, 255, 0.75);
}

.auth-nav-menu, .nav-menu ul {
  margin: 4px 0 0 15px;
  -webkit-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.08);
}

.auth-nav-menu, .nav-menu ul li {
  background: #fff;
  border-top: 1px solid #f4f4f4;
}

.auth-nav-menu, .nav-menu ul li:first-child {
  border-top: 0;
}

.auth-nav-menu, .nav-menu ul li:hover {
  background: #199EB8;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.auth-nav-menu, .nav-menu ul li a {
  color: #333;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  padding: 10px 15px;
}

.auth-nav-menu, .nav-menu ul li a:hover {
  color: #fff;
}

.auth-nav-menu, .nav-menu ul ul {
  margin: 0;
}

/* Mobile Nav Toggle */
#mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  margin: 8px 10px 0 0;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  outline: none;
  cursor: pointer;
}

#mobile-nav-toggle i {
  color: #fff;
}

@media (max-width: 768px) {
  #mobile-nav-toggle {
    display: inline;
  }
  #nav-menu-container{
    display: none;
  }
  #mobile-nav-toggle{
    display: none;
  }
}

/* Mobile Nav Styling */
#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.9);
  left: -260px;
  width: 260px;
  overflow-y: auto;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#mobile-nav ul li {
  position: relative;
}

#mobile-nav ul li a {
  color: #fff;
  font-size: 16px;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
}

#mobile-nav ul li a:hover {
  color: #fff;
}

#mobile-nav ul li li {
  padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: #199EB8;
}

#mobile-nav ul .menu-item-active {
  color: #199EB8;
}

#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  display: none;
}

/* Mobile Nav body classes */
body.mobile-nav-active {
  overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
  left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
  color: #fff;
}

/* ==================================================
  Stats Styling
================================================== */
.stats-row {
  margin-top: 65px;
}

.stats-col .circle {
  display: inline-block;
  width: 160px;
  height: 160px;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #666;
  border: 6px solid #199EB8;
  border-radius: 50%;
  padding: 55px 25px 0 25px;
  position: relative;
}

.stats-col .circle .stats-no {
  color: #fff;
  width: 70px;
  height: 70px;
  line-height: 70px;
  top: -25px;
  right: -15px;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-size: 18px;
  background-color: #E04F00;
  position: absolute;
  border-radius: 50%;
  font-weight: 700;
}

/* ==================================================
  Parallax Styling
  ================================================== */
.block {
  color: #fff;
  height: 490px;
  overflow: hidden;
  padding-top: 85px;
}

.block h2 {
  text-transform: uppercase;
  margin-bottom: 15px;
}

.block p {
  color: #fff;
  margin-bottom: 45px;
}


/* ==================================================
Carousel Styling
   ================================================== */

   .carousel img {
    max-height: 550px;
    margin: 0 auto;
}
/* ==================================================
Service Card Styling
   ================================================== */

.services .card {
  background: #fff;
  border-radius: 2px;
  height: 275px;
  margin: 1rem;
  position: inherit;
  width: 320px;
  box-shadow: 0 3px 6px #999, 0 3px 6px #999;
  text-align: left;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
     -o-transition:color .2s ease-out, background 1s ease-in;
  -ms-transition:color .2s ease-out, background 1s ease-in;
  -moz-transition:color .2s ease-out, background 1s ease-in;
  -webkit-transition:color .2s ease-out, background 1s ease-in;
  /* ...and now override with proper CSS property */
  transition:color .2s ease-out, background 1s ease-in;
}

.service-icon{
  margin-top: 25px;
  margin-right: 5px;
}
.service-description{
      margin: 22px 24px 0px 0px;
}

.services .card:hover{
box-shadow: 0px 12px #444 12px 1px #444;
background:  #199EB8;  
}

.services .card:hover h3 {
  border: #fff solid 4px;
  color: #fff;
  margin-right: 17px;
  padding: 1em;
  cursor: pointer;
  text-decoration: none;
}
.services .card-link:hover{
  text-decoration: none;
  color: white;
}
.services .card:hover p{
  display: none;

}

.services .card:hover{
  background-position: 0 0;
  color:#333;
}

/* ==================================================
  Services Styling
  ================================================== */
.services {
  padding-bottom: 45px;
}

.services h2 {
  color: #199EB8;
  cursor: pointer;
}

.service-col {
  display: table;
  padding-bottom: 45px;
}

.service-col > div > div {
  display: table-cell;
  vertical-align: middle;
}

.service-col > div > div:last-child {
  padding-left: 20px;
}

.service-col .service-icon {
  width: 70px;
  height: 70px;
  margin-bottom: 15px;
  background: #199EB8;
  color: #fff;
  font-size: 24px;
  text-align: center;
  line-height: 70px;
  border-radius: 50%;
  display: inline-block;
}

.service-col h3 {
  color: #199EB8;
}

.service-col p {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #999;
}
/* ==================================================
  Services Pages Styling
  ================================================== */
  
  .service-title{
    color: #199EB8;
    text-align: center;
    /* padding: 40px; */
    font-size: 52px;
  }
  
  .service-pricing-title{
    color: #199EB8;
    text-align: center;
  }

  .service-includes-title{
    color: #199EB8;
    text-align: center;
    
  }

  .service-extra-title{
  color: #199EB8;
  text-align: center;
  }

  .includes-container{
  background: #fff;
  border-radius: 2px;
  height: 485px;
  margin: 1rem;
  position: inherit;
  width: 520px;
  box-shadow: 0 3px 6px #999, 0 3px 6px #999;
  text-align: left;
  }
  
  .pricing-container{
  background: #fff;
  border-radius: 2px;
  height: 260px;
  margin: 1rem;
  position: inherit;
  width: 520px;
  box-shadow: 0 3px 6px #999, 0 3px 6px #999;
  text-align: left;
  padding: 5px;
  }

  .extra-container{  
  background: #fff;
  border-radius: 2px;
  height: 160px;
  margin: 1rem;
  margin-top: 55px;
  position: inherit;
  width: 520px;
  box-shadow: 0 3px 6px #999, 0 3px 6px #999;
  text-align: left;
  
  }
  .second-container{
    float: right
  }

  .includes-list li{
    font-size: 18px;
    color: #333;
    padding: 3px;
  }
  .pricing-list li{
    font-size: 18px;
    color: #333;
    padding: 3px;
  }
  .extra-list li{
    font-size: 18px;
    color: #333;
    /* padding: 3px; */
  }
  .price-table{
    margin-top: 100px;
    margin-left: 30px;
    margin-bottom: 200px;
  }
  .lesson-pricing-container{
    width:100%;
    margin-top: 80px;
  }
  .lesson-pricing-title{
    color: #199EB8;
    text-align: center;
    font-size: 40px;
  }
  th{
    font-family: "Raleway", Helvetica, Arial, sans-serif;
  }
  td{
    font-family: "Raleway", Helvetica, Arial, sans-serif;
  }
   h5{
    font-family: "Raleway", Helvetica, Arial, sans-serif;
  }
  .gallery-title{
    
    color: #199EB8;
    text-align: center;
    font-size: 40px;
    margin-bottom: 80px;
  
  }
  .event-images{
    height: 80%;
    width: 100%;
    

  }
  

   

  @media only screen and (max-width: 600px) {
    .includes-container{
      height: auto;
      width: auto;
    }
    .pricing-container{
      height: auto;
    }
    .extra-container{
      height: auto;
    }
    .price-table{
      width: auto;
      height: auto;
    }
    .lesson-pricing-title{
      color: #199EB8;
    text-align: center;
    font-size: 50px;
    }
    .accordion-header:hover{
      cursor: pointer;
    }
    
    
  }

/* ==================================================
  Team Styling
  ================================================== */
.team {
  padding-bottom: 45px;
}

.team h2 {
  color: #199EB8;
  cursor: pointer;
}

.team-col {
  display: table;
  padding-bottom: 45px;
}

.team-col > div > div {
  display: table-cell;
  vertical-align: middle;
}

.team-col > div > div:last-child {
  padding-left: 20px;
}

.team-col .team-icon {
  width: 70px;
  height: 70px;
  margin-bottom: 15px;
  background: #199EB8;
  color: #fff;
  font-size: 24px;
  text-align: center;
  line-height: 70px;
  border-radius: 50%;
  display: inline-block;
}

.team-col h3 {
  color: #199EB8;
}

.team-col p {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #999;
}
/* ==================================================
  Team Pages Styling
  ================================================== */
  
  .team-title{
    color: #199EB8;
    text-align: center;
    /* padding: 40px; */
    font-size: 52px;
  }
  
  .team-pricing-title{
    color: #199EB8;
    text-align: center;
  }

  .team-includes-title{
    color: #199EB8;
    text-align: center;
    
  }

  .team-extra-title{
  color: #199EB8;
  text-align: center;
  }

  .includes-container{
  background: #fff;
  border-radius: 2px;
  height: 485px;
  margin: 1rem;
  position: inherit;
  width: 520px;
  box-shadow: 0 3px 6px #999, 0 3px 6px #999;
  text-align: left;
  }
  
  .pricing-container{
  background: #fff;
  border-radius: 2px;
  height: 260px;
  margin: 1rem;
  position: inherit;
  width: 520px;
  box-shadow: 0 3px 6px #999, 0 3px 6px #999;
  text-align: left;
  padding: 5px;
  }

  .extra-container{  
  background: #fff;
  border-radius: 2px;
  height: 160px;
  margin: 1rem;
  margin-top: 55px;
  position: inherit;
  width: 520px;
  box-shadow: 0 3px 6px #999, 0 3px 6px #999;
  text-align: left;
  
  }
  .second-container{
    float: right
  }

  .includes-list li{
    font-size: 18px;
    color: #333;
    padding: 3px;
  }
  .pricing-list li{
    font-size: 18px;
    color: #333;
    padding: 3px;
  }
  .extra-list li{
    font-size: 18px;
    color: #333;
    /* padding: 3px; */
  }
  .price-table{
    margin-top: 100px;
    margin-left: 30px;
  }
  .lesson-pricing-container{
    width:100%;
    margin-top: 80px;
  }
  .lesson-pricing-title{
    color: #199EB8;
    text-align: center;
    font-size: 40px;
  }
  th{
    font-family: "Raleway", Helvetica, Arial, sans-serif;
  }
  td{
    font-family: "Raleway", Helvetica, Arial, sans-serif;
  }
   h5{
    font-family: "Raleway", Helvetica, Arial, sans-serif;
  }

  @media only screen and (max-width: 600px) {
    .includes-container{
      height: auto;
      width: auto;
    }
    .pricing-container{
      height: auto;
    }
    .extra-container{
      height: auto;
    }
    .price-table{
      width: auto;
      height: auto;
    }
    .lesson-pricing-title{
      color: #199EB8;
    text-align: center;
    font-size: 50px;
    }
    .accordion-header:hover{
      cursor: pointer;
    }
    
  } 

.team .card {
  background-image: url("../images/team.jpg");
  border-radius: 2px;
  height: 275px;
  margin: 1rem;
  position: inherit;
  width: 320px;
  /* box-shadow: 0 3px 6px #999, 0 3px 6px #999; */
  text-align: left;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    /* background-color: #fff; */
    /* background-clip: border-box; */
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
     /* -o-transition:color .2s ease-out, background 1s ease-in;
  -ms-transition:color .2s ease-out, background 1s ease-in;
  -moz-transition:color .2s ease-out, background 1s ease-in;
  -webkit-transition:color .2s ease-out, background 1s ease-in;
  transition:color .2s ease-out, background 1s ease-in;
  -webkit-filter: grayscale(70%);
 filter: grayscale(70%); */
 -webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;

}

.team-icon{
  margin-top: 25px;
  margin-right: 5px;
}
.team-description{
  margin: 22px 24px 0px 0px;
  color: red;
}

.team .card p{
  display:none;
  color: red;
}

.team .card:hover{
/* box-shadow: 0px 12px #444 12px 1px #444; */
/* background-color:black;  */
/* opacity:.7;  */
/* z-index:10; */
color:red;
} 

.team .card:hover h3 {
  /* border: #fff solid 4px;
  color: #fff;
  margin-right: 17px;
  padding: 1em;
  cursor: pointer;
  text-decoration: none; */
  display:none;
}
.team .card-link:hover{
  text-decoration: none;
  color: red(0, 0%, 100%);
}
.team .card:hover p{
display: inherit;
font-size: 28px;
color:red;
text-align:center;
font:24px sans serif;
text-decoration:none;
text-shadow: 1px 1px black;
}

/* .team-description .card{
  color:red;
} */


/* .team .card:hover{
  background-position: 0 0;
  color:red;
} */

/* ==================================================
  Teams Styling
  ================================================== */
.team {
  padding-bottom: 45px;
}

.team h2 {
  color: #199EB8;
  cursor: pointer;
}

.team-col {
  display: table;
  padding-bottom: 45px;
}

.team-col > div > div {
  display: table-cell;
  vertical-align: middle;
}

.team-col > div > div:last-child {
  padding-left: 20px;
}

.team-col .team-icon {
  width: 70px;
  height: 70px;
  margin-bottom: 15px;
  background: #199EB8;
  color: #fff;
  font-size: 24px;
  text-align: center;
  line-height: 70px;
  border-radius: 50%;
  display: inline-block;
}

.team-col h3 {
  color: white;
  font:24px;
}

.team-col p {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #999;
}
/* ==================================================
  Teams Pages Styling
  ================================================== */
  
  .team-title{
    color: #199EB8;
    text-align: center;
    /* padding: 40px; */
    font-size: 52px;
  }
  
  .team-pricing-title{
    color: #199EB8;
    text-align: center;
  }

  .team-includes-title{
    color: #199EB8;
    text-align: center;
    
  }

  .team-extra-title{
  color: #199EB8;
  text-align: center;
  }

  .includes-container{
  background: #fff;
  border-radius: 2px;
  height: 485px;
  margin: 1rem;
  position: inherit;
  width: 520px;
  box-shadow: 0 3px 6px #999, 0 3px 6px #999;
  text-align: left;
  }
  
  .pricing-container{
  background: #fff;
  border-radius: 2px;
  height: 260px;
  margin: 1rem;
  position: inherit;
  width: 520px;
  box-shadow: 0 3px 6px #999, 0 3px 6px #999;
  text-align: left;
  padding: 5px;
  }

  .extra-container{  
  background: #fff;
  border-radius: 2px;
  height: 160px;
  margin: 1rem;
  margin-top: 55px;
  position: inherit;
  width: 520px;
  box-shadow: 0 3px 6px #999, 0 3px 6px #999;
  text-align: left;
  
  }
  .second-container{
    float: right
  }

  .includes-list li{
    font-size: 18px;
    color: #333;
    padding: 3px;
  }
  .pricing-list li{
    font-size: 18px;
    color: #333;
    padding: 3px;
  }
  .extra-list li{
    font-size: 18px;
    color: #333;
    /* padding: 3px; */
  }
  .price-table{
    margin-top: 100px;
    margin-left: 30px;
  }
  .lesson-pricing-container{
    width:100%;
    margin-top: 80px;
  }
  .lesson-pricing-title{
    color: #199EB8;
    text-align: center;
    font-size: 40px;
  }
  th{
    font-family: "Raleway", Helvetica, Arial, sans-serif;
  }
  td{
    font-family: "Raleway", Helvetica, Arial, sans-serif;
  }
   h5{
    font-family: "Raleway", Helvetica, Arial, sans-serif;
  }

  @media only screen and (max-width: 600px) {
    .includes-container{
      height: auto;
      width: auto;
    }
    .pricing-container{
      height: auto;
    }
    .extra-container{
      height: auto;
    }
    .price-table{
      width: auto;
      height: auto;
    }
    .lesson-pricing-title{
      color: #199EB8;
    text-align: center;
    font-size: 50px;
    }
    .accordion-header:hover{
      cursor: pointer;
    }
    
  }

  .photo-container{
  border-radius: 2px;
  height: 485px;
  margin: 1rem;
  position: inherit;
  width: 520px;
  box-shadow: 0 3px 6px #999, 0 3px 6px #999;
  text-align: left;
  }

  .familyphoto{
    width: 520px;
    height: 485px;
  }

  .team-container{
  background: #fff;
  /* border-radius: 2px; */
  height: 500px;
  margin: 1rem;
  /* position: relative; */
  width: 560px;
  /* box-shadow: 0 3px 6px #999, 0 3px 6px #999; */
  text-align: left;
  font-size: 5;
  /* padding: 5px; */
  overflow: auto;
  padding-bottom: 10px;
  }

  .team-container h1{
  font-size: 40px;
  text-align: center;
}

.team-container i,
.team-container p{
  font-size: 24px;
}

  .about-team-title{
  color: #199EB8;
    text-align: center;
    font-size: 50px;
  }
  .about-farm-title{
  color: #199EB8;
    text-align: center;
    font-size: 50px;
  }
  .about-farm-list{
    height: 20px;
    /* overflow: auto;
    padding-bottom: 100px; */

  }
  .about-farm-list li{
    font-size: 1vw;
  }

  .about-accordion-list li{
    width: 100%;
    color: black;
  }

  .about-farm-container{  
  background: #fff;
  /* border-radius: 2px; */
  height: 490px;
  margin: 1rem;
  position: inherit;
  width: 520px;
  /* box-shadow: 0 3px 6px #999, 0 3px 6px #999; */
  text-align: left;
  overflow: auto;
    padding-bottom: 100px;
  
  }

  .about-farm-title{
  color: #199EB8;
  text-align: center;
  }

.team-container h1{
  font-size: 40px;
  text-align: center;
}

.team-accordion{
  color: black;

}

.team-card-title{
  position: inherit;

}
/* ==================================================
  Call-to-action Styling
  ================================================== */
.cta {
  background-color: #199EB8;
  padding: 25px 0;
}

.cta h2 {
  margin-bottom: 5px;
}

.cta h2,
.cta p {
  color: #fff;
}

.cta p {
  margin-bottom: 0;
  opacity: .75;
}

.cta .btn-ghost {
  position: relative;
  top: 13px;
}






/* ==================================================
  Team Styling
  ================================================== */
.team h2 {
  color: #199EB8;
}

.team .col-sm-3 {
  padding: 0;
}

.team .card > a {
  display: block;
}

.team .card img {
  width: 100%;
}

.team .card h4 {
  color: #fff;
  text-transform: uppercase;
}

.team .card p {
  font-size: 11px;
  color: #fff;
  opacity: .75;
  margin: 0;
  padding: 0 35px;
}

.team .card .social-nav {
  margin-bottom: 45px;
}

.team .card .social-nav a {
  color: #fff;
  font-size: 16px;
  margin: 0 4px;
}

.team .card .social-nav a:hover {
  opacity: .75;
}

.team .card:hover .team-over {
  opacity: 1;
  visibility: visible;
}

.team .card:hover .card-title-wrap {
  background-color: #199EB8;
}

.team .card:hover .card-title-wrap .card-title, .team .card:hover .card-title-wrap .card-text {
  color: #fff;
}

.team .team-over {
  padding-top: 45px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.team .card-title-wrap {
  padding: 15px 25px;
  position: relative;
  z-index: 9;
  background-color: #fff;
}

.team .card-title-wrap .card-title, .team .card-title-wrap .card-text {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  display: block;
  margin: 0;
}

.team .card-title-wrap .card-title {
  font-size: 24px;
  color: #333;
}

.team .card-title-wrap .card-text {
  font-size: 18px;
  color: #999;
}

/* ==================================================
  Contact Section
  ================================================== */
#contact {
  background: #f7f7f7;
  padding: 80px 0;
}

#contact h2 {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  color: #199EB8;
}

#contact .info i {
  font-size: 32px;
  color: #199EB8;
  float: left;
}

#contact .info p {
  padding: 0 0 10px 50px;
  line-height: 24px;
}

#contact .form #sendmessage {
  color: #199EB8;
  border: 1px solid #199EB8;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #sendmessage.show, #contact .form #errormessage.show, #contact .form .show {
  display: block;
}

#contact .form .validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

#contact .form input, #contact .form textarea {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#contact .form button[type="submit"] {
  background: #199EB8;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

#contact .form button[type="submit"]:hover {
  background: #23c2e1;
}

@media screen and (max-width: 768px) {
    .maps{
        position: inherit;
        width:300px;
        height: 260px;
        padding-left:40px;
        padding-right: 0px;
    }
}
/* @media screen and (max-width: 768px) {
    .maps{
        width:600px;
        height: 300px;
    }
  } */

/* ==================================================
  Footer Styling
  ================================================== */
.site-footer {
  background-color: #111;
  /* padding: 0; */
  height: 60px;
  display: relative;
  margin-top: 450px;
  clear:both;
}

.site-footer h2, .site-footer p {
  color: #fff;
}

.site-footer p {
  opacity: .75;
  line-height: 2.0925;
}

.site-footer h2,
.site-footer .btn {
  margin-bottom: 25px;
}

.site-footer .social-nav a {
  color: #fff;
  opacity: .25;
}

.site-footer .social-nav a:hover {
  opacity: 1;
}

/* .site-footer .bottom {
  background-color: #000;
  padding: 20px;
  margin-top: 500px;
} */

.site-footer .bottom .list-inline, .site-footer .bottom p {
  margin: 0;
}

.site-footer .bottom .list-inline {
  position: relative;
  top: 5px;
}

.site-footer .bottom .list-inline a {
  color: #fff;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  margin-right: 15px;
}

.site-footer .bottom .list-inline a:hover {
  color: #199EB8;
}

.site-footer .credits {
  color: #ddd;
}

.site-footer .credits a {
  color: #199EB8;
}

/* ==================================================
  Responsive Styling
  ================================================== */
@media (max-width: 61.9em) {
  section,
  .block,
  .cta {
    padding: 35px 0;
  }
  .services {
    padding-bottom: 5px;
  }
  p,
  .block p {
    margin: 0;
  }
  .hero-brand {
    margin-bottom: 35px;
  }
  .tagline {
    margin: 35px 0;
  }
  h1 {
    font-size: 32px;
    margin: 0;
  }
  h2,
  .tagline {
    font-size: 24px;
  }
  h2 {
    margin-bottom: 25px;
  }
  h3 {
    font-size: 14px;
  }
  .hero {
    padding: 75px 0;
  }
  .stats-col {
    margin-bottom: 25px;
  }
  .block {
    height: auto;
  }
  .service-col {
    padding-bottom: 30px;
  }
  .calendar-grid .card h3.card-title {
    font-size: 18px !important;
  }
  .calendar-grid .card .card-text {
    font-size: 13px;
  }
  .team .team-over {
    padding-top: 20px;
  }
  .team .card .social-nav {
    margin-bottom: 15px;
    padding: 0;
  }
  .site-footer .social-nav {
    margin-bottom: 35px;
  }
  .site-footer .list-inline {
    text-align: center;
    padding-bottom: 15px;
  }
  .site-footer .list-inline li {
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .block {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.modal-header{
  display: initial;
  margin-top: -10px;
}
/* ==================================================
  calendar Styling
  ================================================== */
  .calendar {
    background-color: #edf6ff;
    padding-bottom: 0;
  }
  
  .calendar h2 {
    color: #199EB8;
    margin-bottom: 25px;
  }
  
  .calendar-grid {
    margin-top: 65px;
  }
  
  .calendar-grid .row {
    margin: 0;
  }
  
  .calendar-grid .row > div {
    padding: 0;
  }
  
  .calendar-grid .row > div .card img {
    width: 100%;
  }
  
  .calendar-grid .row > div .card .calendar-over {
    position: absolute;
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  
  .calendar-grid .row > div .card .calendar-over > div {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  .calendar-grid .row > div .card .calendar-over .card-title {
    color: #fff;
    font-size: 30px;
  }
  
  .calendar-grid .row > div .card .calendar-over .card-text {
    color: #fff;
    opacity: .75;
    padding: 0 45px;
  }
  
  .calendar-grid .row > div .card:hover .calendar-over {
    opacity: 1;
    visibility: visible;
  }